import { Col, Content, Spacer, Button, Label, Div, Circle, Row } from '@gower/react-ui/components';
import { AppLogo, AppName } from 'lib/components/logo';
import { PropsWithChildren } from 'react';


export type HeaderProps = {
};

export const Header: React.FC<PropsWithChildren<HeaderProps>> = ( { children } ) => {

    return <>
        <Content color='white' box-shadow-1 height={64} noWrap zIndex={1}>
            <Col px-2 center>
                <Button pa-1 borderRadiusAll={5} noneTheme text reactLink='/'>
                    <Row justifyCenter fullWidth noWrap noScroll>
                        <Col center pr-2 >
                            <AppLogo color="green-5" size={45} />
                        </Col>
                    </Row>
                </Button>
            </Col>
            <Spacer noScroll height="100%">
                {children}
            </Spacer>
            <Col fullHeight center padding={10}>
                <Button
                    color="green-5"
                    legacyLink="https://www.italmedicasalud.com.ar/"
                    xnormal
                    label="Ir a ITALMEDICA"
                />
            </Col>
        </Content>
    </>;
};

