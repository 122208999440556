import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import { Col, Content } from '@gower/react-ui/components';
import { Page404 } from '@gower/react-ui/template/page404';
import { RoutesEnum } from './routes';
import { LayoutEmpty } from '../layout-empty';

// VIEWS
import { HomeView } from 'views/home';

export const Router = () => {

    return <>
        <BrowserRouter>
            <Routes>
                <Route path={RoutesEnum.Base} element={<LayoutEmpty />} >
                    <Route path={RoutesEnum.Base} element={<HomeView />} />
                    <Route path={RoutesEnum.Home} element={<HomeView />} />
                    <Route path='*' element={<NotFoundPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </>;
};


type RequireAuthProps = {
    reverse?: boolean;
    Element?: any;
};
const RequireAuth: React.FC<RequireAuthProps> = ( { Element, reverse } ) => {

    return <Element />;
};

const NotFoundPage: React.FC = () => {
    return (
        <Content fullWidth fullHeight justifyCenter>
            <Col center>
                <Page404 />
            </Col>
        </Content>
    );
};