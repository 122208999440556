import { Col, Content, Div, Spacer } from '@gower/react-ui/components';
import { AppLogo } from 'lib/components/logo';
import { Header } from './header';
import React from 'react';
import { Outlet } from 'react-router-dom';

export const LayoutEmpty: React.FC = () => {
    return <>
        <Content viewPortWidth viewPortHeight color='surface-2' columns>
            <Col>
                <Header />
            </Col>
            <Spacer noScroll vertical
                style={{
                    background: 'linear-gradient(to top, rgb(237, 245, 240), rgb(255, 255, 255))',
                    
                }}>
                <Outlet />
            </Spacer>
        </Content>
    </>;
};