import { Color, Colors } from '@gower/react-ui';
import React from "react";


export type AppLogoProps = {
  color?: Color;
  dark?: boolean;
  size?: number;
  v1?: boolean;
  v2?: boolean;
};

export const AppLogo: React.FunctionComponent<AppLogoProps> = ( {
  dark, v1, v2,
  ...props
} ) =>
{

  if ( typeof v1 === 'undefined' && typeof v2 === 'undefined' )
    v2 = true;

  let width = 30;
  let height = 30;
  if ( typeof props.size !== 'undefined' )
  {
    width = props.size;
    height = props.size;
  }

  return (
    <>
      <Iso height={height} width={width} background={dark ? `var(--white)` : `var(--blue-9)`} color={Colors[props.color]} />
    </>
  );


};

function Iso ( { ...props } )
{
  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 514.15 502.78"
      dangerouslySetInnerHTML={{
        __html:
          `
          <path d="M1,88.24c-.02,113.63-.06,227.26,.03,340.89,0,5.61,.84,11.27,1.74,16.83,1.52,9.45,5.52,17.92,10.85,25.85,11.59,17.27,28.65,26.27,48.26,29.86,8.72,1.6,18.24-.35,27.29-1.5,10.18-1.3,19.25-5.76,27.44-11.96,17.8-13.48,27.73-31.14,28.01-53.67,.23-18.32,.04-36.65,.05-54.98,.03-44.15,.07-88.3,.11-132.45l1.13-.07c14.87,14.82,29.68,29.69,44.62,44.44,7.11,7.02,13.87,14.55,21.76,20.58,27.83,21.27,67.18,18.99,92.65-5.12,17.91-16.95,35.43-34.31,53.14-51.47,3.62-3.51,7.29-6.98,11.72-11.22v6.01c0,60.15-.04,120.31,.07,180.46,.01,5.46,.41,11.05,1.61,16.35,7.77,34.1,35.91,53.31,68.04,54.84,5.35,.26,10.8-.97,16.16-1.82,10.03-1.61,19.23-5.45,27.44-11.4,19.78-14.32,29.47-33.95,29.56-58.2,.44-118.62,1.23-237.23,.88-355.85-.13-41.89-29.67-72.23-72.89-73.37-4.52-.12-9.11,.98-13.62,1.76-15.65,2.71-28.79,10.05-39.49,21.76-2.47,2.7-4.78,5.58-7.4,8.12-40.86,39.72-81.76,79.39-122.67,119.07-.9,.88-1.94,1.62-3.44,2.86-1.26-1.53-2.21-2.92-3.38-4.1-36.14-36.21-72.29-72.4-108.48-108.56-1.25-1.25-2.84-2.16-4.27-3.22-4.13-5.57-7.77-11.6-12.5-16.61C114.41,10.68,100.2,3.93,84.75,1.44c-12.28-1.98-25.03-.88-37.03,3.71-14.34,5.49-25.84,14.43-34.73,27.11C6.46,41.59,2.97,52.04,1.17,62.83c-1.38,8.25-.2,16.93-.16,25.41h0Z" fill="#fefefe" />
          <path d="M137.92,38.97c1.43,1.07,3.02,1.97,4.27,3.22,36.19,36.16,72.34,72.35,108.48,108.56,1.17,1.17,2.13,2.56,3.38,4.1,1.5-1.24,2.53-1.98,3.44-2.86,40.9-39.67,81.81-79.35,122.67-119.07,2.62-2.55,4.93-5.42,7.4-8.12,10.71-11.71,23.85-19.05,39.49-21.76,4.51-.78,9.1-1.88,13.62-1.76,43.22,1.14,72.76,31.49,72.89,73.37,.36,118.61-.43,237.23-.88,355.85-.09,24.25-9.78,43.88-29.56,58.2-8.21,5.94-17.41,9.79-27.44,11.4-5.35,.86-10.81,2.08-16.16,1.82-32.12-1.53-60.27-20.75-68.04-54.84-1.21-5.3-1.6-10.89-1.61-16.35-.11-60.15-.07-120.31-.07-180.46v-6.01c-4.43,4.24-8.1,7.7-11.72,11.22-17.71,17.16-35.23,34.52-53.14,51.47-25.48,24.11-64.83,26.38-92.65,5.12-7.89-6.03-14.66-13.56-21.76-20.58-14.93-14.75-29.75-29.62-44.62-44.44l-1.26-1.35,.05,.05c-.05-.53-.09-1.05-.14-1.58-.03-58.96-.05-117.93-.08-176.89,0-.66-.14-1.32-.21-1.98-.56-4.17-.77-8.42-1.77-12.48-1.16-4.7-3.02-9.23-4.57-13.84h-.01Z" fill="#bd243d" />
          <path d="M144.69,245.73l-.05-.05c.05,.47,.09,.95,.13,1.42-.04,44.15-.08,88.3-.11,132.45,0,18.33,.18,36.66-.05,54.98-.28,22.53-10.21,40.19-28.01,53.67-8.19,6.2-17.26,10.67-27.44,11.96-9.05,1.15-18.57,3.1-27.29,1.5-19.61-3.59-36.67-12.6-48.26-29.86-5.33-7.93-9.33-16.4-10.85-25.85-.89-5.56-1.73-11.22-1.74-16.83-.08-113.63-.04-227.26-.03-340.89,.59,.73,1.5,1.38,1.72,2.21,3.51,13.26,10.25,24.5,20.3,33.9,8.27,7.75,16.19,15.86,24.27,23.81,32.18,32.25,64.36,64.51,96.54,96.76l.86,.81h0Z" fill="#019669" />
          <path d="M144.78,247.1c-.04-.47-.09-.95-.13-1.42l1.26,1.35c-.38,.02-.75,.05-1.13,.07h0Z" fill="#d5d5d5" />
          <path d="M47.29,148.16c-8.08-7.95-16-16.07-24.27-23.81-10.04-9.4-16.78-20.64-20.3-33.9-.22-.83-1.13-1.48-1.72-2.21-.04-8.49-1.21-17.16,.16-25.41,1.8-10.79,5.29-21.24,11.82-30.56C21.88,19.58,33.38,10.64,47.72,5.15,59.72,.56,72.46-.54,84.75,1.44c15.46,2.49,29.66,9.24,40.67,20.92,4.72,5.01,8.36,11.04,12.5,16.61,1.55,4.61,3.41,9.14,4.57,13.84,1,4.06,1.21,8.31,1.77,12.47M47.29,148.16c32.18,32.25,64.36,64.51,96.54,96.76,.24-.26,.48-.51,.71-.77-.02-58.96-.05-117.93-.08-176.89,0-.66-.14-1.32-.21-1.98" fill="#d5d5d5" />
          <path d="M143.84,244.92c.24-.26,.48-.51,.71-.77,.05,.53,.09,1.05,.14,1.58-.28-.27-.57-.54-.86-.81h0Z" fill="#d5d5d5" />
      `}}>

    </svg>
  );
}


export default AppLogo;
